import React from "react";
import { Redirect } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { fetchCurrentShift } from "../../../store/thunks/shiftsThunk";
import { fetchDiningTableSections } from "../../../store/thunks/diningTableSectionsThunk";
import { fetchSalesOrders } from "../../../store/thunks/salesOrdersThunk";
import { getCurrentShift } from "../../../store/slices/commons/currentShiftSliceCommon";
import { getDiningTableSections, getDiningTables, unloadDiningTableSections } from "../../../store/slices/datasources/diningTableSectionsSliceDatasource";
import { unloadDiningTables } from "../../../store/slices/datasources/diningTablesSliceDatasource";
import { getTableServiceSalesOrders, unloadSalesOrders } from "../../../store/slices/datasources/salesOrdersSliceDatasource";
import { getCurrentStoreBranch } from "../../helpers/authentication";
import { isWaiterAuthorized, isStoreOpen } from "../../helpers/authentication";
import WithLoadingPage from "../commons/withLoadingPage";
import DiningTableSections from "./partials/diningTableSections";
import DiningTables from "./partials/diningTables";
import TableInformation from "./partials/tableInformation";
import WaiterLoginForm from "./partials/waiterLoginForm";

const DiningTableMap = props => {
    
    const dispatch = useDispatch();
    const { id: storeBranchId, name: storeBranchName } = getCurrentStoreBranch();
    const [diningTableSectionId, setDiningTableSectionId] = React.useState(0);
    const currentShift = useSelector(getCurrentShift);
    const diningTableSections = useSelector(getDiningTableSections);
    const diningTables = useSelector(getDiningTables(diningTableSectionId));
    const tableService = useSelector(getTableServiceSalesOrders);
	const { setLoading } = props;
    
    React.useEffect(() => {
        return () => {
            dispatch(unloadDiningTableSections());
            dispatch(unloadDiningTables());
            dispatch(unloadSalesOrders());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        async function initialize() {
            setLoading(true);
            await dispatch(fetchCurrentShift());
            await dispatch(fetchDiningTableSections(getDiningTableSectionQueryFilter()));
            await dispatch(fetchSalesOrders(getSalesOrderQueryFilter()));
            setLoading(false);
        }

        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    React.useEffect(() => {
        if (diningTableSections && diningTableSections.length) {
            setDiningTableSectionId(diningTableSections[0].id);
        }
    }, [diningTableSections]);

    const getDiningTableSectionQueryFilter = () => {
        const queryFilter = {
            storeBranchId: storeBranchId,
            withDiningTables: true
        };

        return {
            query: queryFilter
        };
    }

    const getSalesOrderQueryFilter = () => {
        const queryFilter = {
            storeBranchId: storeBranchId,
            tableService: true
        };

        return {
            query: queryFilter
        };
    }

    const shiftInformation = () => {
        if (currentShift.is_closed === 0) {
            return (
                <React.Fragment>
                    Shift Date : {moment(currentShift.from_date).format("DD-MM-YYYY")} - <span className="text-green-600">Open</span>
                </React.Fragment>
            );
        }

        return (
            <span className="text-red-600">Closed</span>
        );
    }

    if (isWaiterAuthorized() && !isStoreOpen()) return <Redirect to="/shift/start-day" />;
    if (isWaiterAuthorized() &&  isStoreOpen()) return <Redirect to="/dashboard" />;
    return (
        <React.Fragment>
            <div className="widget card w-full">
                <div className="card-body flex justify-between h-full mt-5 mb-10">
                    <div className="flex flex-col w-full mr-15">
                        <div className="flex justify-between mt-5 w-full">
                            <strong className="text-base">{storeBranchName}</strong>
                            <div className="ml-auto mr-5">
                                <strong>{shiftInformation()}</strong>
                            </div>
                        </div>
                        <DiningTableSections diningTableSections={diningTableSections} handleSelectDinignTableSection={setDiningTableSectionId} />
                        <DiningTables diningTables={diningTables} tableService={tableService} handleOpenTable={() => { return; }}/>
                        <TableInformation />
                    </div>
                    <div className="flex ml-auto w-300 border-l">
                        <WaiterLoginForm />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
 
export default WithLoadingPage(DiningTableMap);