import React from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { fetchDiningTableSections } from "../../../store/thunks/diningTableSectionsThunk";
import { fetchSalesOrders, fetchSalesOrder } from "../../../store/thunks/salesOrdersThunk";
import { getDiningTableSections, getDiningTables, unloadDiningTableSections } from "../../../store/slices/datasources/diningTableSectionsSliceDatasource";
import { unloadDiningTables } from "../../../store/slices/datasources/diningTablesSliceDatasource";
import { getTableServiceSalesOrders, unloadSalesOrders } from "../../../store/slices/datasources/salesOrdersSliceDatasource";
import { getCurrentStoreBranch, isWaiterAuthorized } from "../../helpers/authentication";
import WithLoadingPage from "../commons/withLoadingPage";
import PageSubHeader from "../elements/pageSubHeader";
import DiningTableSections from "./partials/diningTableSections";
import DiningTables from "./partials/diningTables";
import TableInformation from "./partials/tableInformation";
import FormBookingDiningTable from "./partials/formOpenTableService";

const Dashboard = props => {
    
    const history = useHistory();
	const dispatch = useDispatch();
    const { id: storeBranchId } = getCurrentStoreBranch();
    const [diningTableSectionId, setDiningTableSectionId] = React.useState(0);
    const diningTableSections = useSelector(getDiningTableSections);
    const diningTables = useSelector(getDiningTables(diningTableSectionId));
    const tableService = useSelector(getTableServiceSalesOrders);
	const { setLoading } = props;
    const [formBookingDiningTable, setFormBookingDiningTable] = React.useState({ showDialog: false, diningTable: {} })
	
    React.useEffect(() => {
        return () => {
            dispatch(unloadDiningTableSections());
            dispatch(unloadDiningTables());
            dispatch(unloadSalesOrders());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        async function initialize() {
            setLoading(true);
            await dispatch(fetchDiningTableSections(getDiningTableSectionQueryFilter()));
            await dispatch(fetchSalesOrders(getSalesOrderQueryFilter()));
            setLoading(false);
        }

        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    React.useEffect(() => {
        if (diningTableSections && diningTableSections.length) {
            setDiningTableSectionId(diningTableSections[0].id);
        }
    }, [diningTableSections]);

    const getDiningTableSectionQueryFilter = () => {
        const queryFilter = {
            storeBranchId: storeBranchId,
            withDiningTables: true
        };

        return {
            query: queryFilter
        };
    }

    const getSalesOrderQueryFilter = () => {
        const queryFilter = {
            storeBranchId: storeBranchId,
            tableService: true
        };

        return {
            query: queryFilter
        };
    }

    const handleOpenTable = async diningTable => {
        if (isWaiterAuthorized()) {
            const query = { 
                tableService: true, 
                diningTable: diningTable.id 
            };
 
            await dispatch(fetchSalesOrder({ query }))
                .then(unwrapResult)
                .then(result => {
                    if (result.hasOwnProperty("salesOrder")) {
                        const salesOrder = result.salesOrder[Object.keys(result.salesOrder).toString()];
                        return history.push(`order/${salesOrder.code}`);
                    }
                    
                    setFormBookingDiningTable({ showDialog: true, diningTable });
                });
        }
    }

    const handleCancelOrder = () => {
        setFormBookingDiningTable({ showDialog: false, diningTable: {} });
    }

	return (
		<React.Fragment>
            <PageSubHeader title="Dashboard" />
            <FormBookingDiningTable 
                showDialog={formBookingDiningTable.showDialog} 
                diningTable={formBookingDiningTable.diningTable} 
                handleCancelOrder={handleCancelOrder} 
            />
            <div className="widget card w-full h-full">
                <div className="card-body flex h-full mt-5 mb-10">
                    <div className="flex flex-col w-full mr-15">
                        <DiningTableSections diningTableSections={diningTableSections} handleSelectDinignTableSection={setDiningTableSectionId} />
                        <DiningTables diningTables={diningTables} tableService={tableService} handleOpenTable={handleOpenTable}/>
                        <TableInformation />
                    </div>
                </div>
            </div>
        </React.Fragment>
	);
}

export default WithLoadingPage(Dashboard);