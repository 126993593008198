import { createAsyncThunk } from "@reduxjs/toolkit";
import fsrHttpInstance, { fsrHttpConfiguration } from "../serviceFsrClients";
import { setFlashError } from "../slices/commons/flashSliceCommon";
import { normalizeEntity } from "../entities/salesOrderEntity";

export const fetchSalesOrders = createAsyncThunk(
	"datasource/salesOrders/fetchSalesOrders",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/sales-orders/index", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.salesOrders);

			return normalized;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The order could not be loaded. Please, try again."))
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const fetchSalesOrder = createAsyncThunk(
	"datasource/salesOrders/fetchSalesOrder",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/sales-orders/view", {...params, ...fsrHttpConfiguration()}));
			const normalized = normalizeEntity(response.data.salesOrder);

			return { 
				...normalized
			};
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The order could not be loaded. Please, try again."))
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const fetchSalesOrderInfo = createAsyncThunk(
	"datasource/salesOrders/fetcSalesOrderSummary",
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/sales-orders/view", {...params, ...fsrHttpConfiguration()}));
			
			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The order could not be loaded. Please, try again."))
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const fetchStationOrder = createAsyncThunk(
	"datasources/salesOrders/fetchStationOrder", 
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/sales-orders/print-station-order", {...params, ...fsrHttpConfiguration()}));

			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The station order could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const fetchPresettlement = createAsyncThunk(
	"datasources/salesOrders/fetchPresettlement", 
	async (request, thunkAPI) => {
		try {
			const params = request ? { params: request.query } : {};
			const response = await fsrHttpInstance.then(fsrService => fsrService.get("vendor/sales-orders/print-presettlement", {...params, ...fsrHttpConfiguration()}));

			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The presettlement bill could not be loaded. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const tableService = createAsyncThunk(
	"transaction/salesOrders/tableService",
	async (request, thunkAPI) => {
		try {
            const formData = { ...request };
			const response = await fsrHttpInstance.then(fsrService => fsrService.post("vendor/sales-orders/table-service", formData, fsrHttpConfiguration()));

			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The order could not be create. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const saveOrder = createAsyncThunk(
	"transaction/salesOrders/saveOrder", 
	async (request, thunkAPI) => {
		try {
			const formData = { ...request };
			const response = await fsrHttpInstance.then(fsrService => fsrService.post("vendor/sales-orders/update", formData, fsrHttpConfiguration()));

			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The order could not be save. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
)

export const cancelItem = createAsyncThunk(
	"transaction/salesOrders/cancelItem", 
	async (request, thunkAPI) => {
		try {
			const formData = { ...request };
			const response = await fsrHttpInstance.then(fsrService => fsrService.post("vendor/sales-orders/cancel-item", formData, fsrHttpConfiguration()));
			const normalized = normalizeEntity(response.data.salesOrder);

			return { 
				...normalized,
				cancelledItem: response.data.cancelledItem
			};
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The order item could not be cancelled. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);

export const voidSalesOrder = createAsyncThunk(
	"transaction/salesOrders/voidSalesOrder", 
	async (request, thunkAPI) => {
		try {
			const formData = { ...request };
			const response = await fsrHttpInstance.then(fsrService => fsrService.post("vendor/sales-orders/void", formData, fsrHttpConfiguration()));

			return response.data;
		} catch (exception) {
			thunkAPI.dispatch(setFlashError("The order could not be void. Please, try again."));
			return thunkAPI.rejectWithValue(exception.response.data);
		}
	}
);