import React from "react";
import { NavLink } from "react-router-dom";

const Login = () => {
    
	const [loading, setLoading] = React.useState(false);
	const [request, setRequest] = React.useState({ email: "", password: "" });

    const handleInputChange = (element) => {
		const { name, value } = element.target
		setRequest({...request, [name]: value});
	}

	const handleFormSubmit = async (event) => {
		event.preventDefault();
		setLoading(true);
	}

    return (  
        <React.Fragment>
            <div className="flex flex-row flex-grow justify-between">
                <div className="flex cover"></div>
                <div className="flex w-1/4">
                    <div className="authentication-page pt-80 px-48">
                        <div className="text-center">
                            <img src={require("../../assets/images/logo.png").default} alt="logo" />
                        </div>
                        <h5 className="mb-0 mt-16">Forgot your password?</h5>
				        <p className="font-size-13 text-muted">It's okay. It's one of those days. Just enter your email below and you'll receive the instruction to reset your password.</p>
                        <form className="mt-20" onSubmit={handleFormSubmit}>
                            <div className="form-group">
                                <label className="form-label" htmlFor="email">Your email address</label>
                                <input id="email" name="email" type="email" className="form-control" value={request.email} onChange={handleInputChange} placeholder="Enter your email address here" />       
                                <div className="text-right">
                                    <NavLink to="/login" className="text-12">
                                        Already remember your password ?
                                    </NavLink>
                                </div>
                            </div>
                            <div className="row mt-40">
                                <button className="btn btn-primary mr-2 uppercase" disabled={loading}>Send Instruction</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
 
export default Login;