import React from "react";
import { Dialog, Transition } from "@headlessui/react";

const FormMoveDiningTable = ({ showDialog, currentDiningTable, diningTableSections, diningTables, handleMoveDiningTable, handleCloseDialog }) => {
    
    const [selectedDiningTableSection, setSelectedDiningTableSection] = React.useState(null);
    const [diningTableId, setDiningTableId] = React.useState(0);

    const handleSelectDinignTableSection = diningTableSection => {
        setSelectedDiningTableSection(diningTableSection);
    }

    const handleApplyUpdate = () => {
        handleMoveDiningTable(diningTableId);
    }

    return (
        <Transition.Root show={showDialog} as={React.Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={handleCloseDialog}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child as={React.Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <Dialog.Overlay className="fixed inset-0 bg-light-600 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &nbsp;
                    </span>
                    <Transition.Child as={React.Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <div className="inline-block overflow-hidden shadow-xl text-left transform transition-all align-middle w-1/2">
                            <div className="widget card">
                                <div className="card-header bg-primary">
                                    <h3 className="card-title text-white">Move Table</h3>
                                </div>
                                <div className="card-body">
                                    <div className="classic card h-full">
                                        <div className="card-header bg-gray-100">
                                            <h3 className="card-title">
                                                Select Table
                                            </h3>
                                        </div>
                                        <div className="card-body">
                                            {!selectedDiningTableSection && 
                                                <div className="grid grid-cols-4 grid-rows-4 grid-flow-row gap-4">
                                                    {diningTableSections.map(diningTableSection => 
                                                        <div key={diningTableSection.id}>
                                                            <button className="btn btn-primary w-full h-70" style={{ display: "inline-block" }} onClick={() => handleSelectDinignTableSection(diningTableSection)}>
                                                                {diningTableSection.name}
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            {selectedDiningTableSection && 
                                                <div className="grid grid-cols-4 grid-rows-4 grid-flow-row gap-4">
                                                    <div>
                                                        <button className="btn btn-warning w-full h-70" style={{ display: "inline-block" }} onClick={() => setSelectedDiningTableSection(null)}>
                                                            {selectedDiningTableSection.name}
                                                        </button>
                                                    </div>
                                                    {selectedDiningTableSection.dining_tables.map(id => 
                                                        <div key={id}>
                                                            <input type="radio" className="btn-check" id={`dinignTable${id}`} name="diningTableId" value={id} onChange={e => setDiningTableId(e.target.value)} defaultChecked={currentDiningTable.id === id} />
                                                            <label className="btn btn-outline-primary w-full h-70" htmlFor={`dinignTable${id}`}>{diningTables[id].number}</label>
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer bg-gray-100 text-right">
                                    <button type="button" className="btn btn-outline-danger uppercase" onClick={handleCloseDialog}>
                                        &nbsp;&nbsp;Cancel&nbsp;&nbsp;
                                    </button>
                                    &nbsp;
                                    <button type="button" className="btn btn-primary uppercase" onClick={handleApplyUpdate}>
                                        &nbsp;&nbsp;Apply&nbsp;&nbsp;
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
 
export default FormMoveDiningTable;