import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { orderBy } from "lodash";
import { fetchShifts } from "../../../store/thunks/shiftsThunk";
import { getShifts, getShiftPagination, unloadShifts } from "../../../store/slices/datasources/shiftsSliceDatasource";
import WithLoadingPage from "../commons/withLoadingPage";
import PageSubHeader from "../elements/pageSubHeader";
import Paginator from "../elements/paginator";
import DataTable from "./components/dataTable";

const Shifts = props => {
    
    const dispatch = useDispatch();
    const shifts = useSelector(getShifts);
    const pagination = useSelector(getShiftPagination);
    const { setLoading } = props;
    const [search, setSearch] = React.useState("");
    const [currentPage, setCurrentPage] = React.useState(1);

    React.useEffect(() => {
        return () => {
            dispatch(unloadShifts());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        async function initialize() {
            setLoading(true);
            await dispatch(fetchShifts(getQueryFilter()));
            setLoading(false);
        }
        
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, currentPage]);

    const getQueryFilter = () => {
        const queryFilter = {
            latest: true,
            search: search,
            page: currentPage,
            paginate: 25,
        };

        return {
            query: queryFilter
        };
    }

    const handleSearchInputChange = element => {
		const { value } = element.target;
		setSearch(value);
	}

    const handleSearch = async () => {
        setLoading(true);
        await dispatch(fetchShifts(getQueryFilter()));
        setLoading(false);
    }

    const handlePageChange = page => {
        if (currentPage !== page) {
			setCurrentPage(page);
		}
    }

    return (
        <React.Fragment>
            <PageSubHeader title="Shift Logs" />
            <div className="w-full">
                <div className="classic card">
                    <div className="card-header bg-light-600">
                        <div className="flex align-middle">
                            <div className="input-group">
                                <input id="search" name="search" type="text" className="form-control input-group-item" placeholder="Search..." value={search} onChange={handleSearchInputChange} />
                                <button className="btn btn-primary uppercase input-group-item" onClick={handleSearch}>
                                    <i className="las la-search" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="card-body text-left">
                        <DataTable shifts={orderBy(shifts, "id", "desc")} />
                        <Paginator pagination={pagination} handlePageChange={handlePageChange} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
 
export default WithLoadingPage(Shifts);