import React from "react";

const SmallPaginator = ({ itemsCount, pageSize, currentPage, handleOnPrev, handleOnNext }) => {

    const pagesCount = Math.ceil(itemsCount / pageSize);
    
	if (pagesCount <= 1) return null;
    return (
        <div className="flex">
            <button type="button" className="btn btn-primary w-50 h-40" onClick={handleOnPrev}>
                <i className="fas fa-arrow-left m-auto" />
            </button>
            <span className="flex items-center text-14 mx-10">Page {currentPage} of {pagesCount}</span>
            <button type="button" className="btn btn-primary w-50 h-40" onClick={handleOnNext}>
                <i className="fas fa-arrow-right m-auto" />
            </button>
        </div>
    );
}
 
export default SmallPaginator;