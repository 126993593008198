import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { unwrapResult } from '@reduxjs/toolkit'
import { fetchCurrentShift } from "../../../../store/thunks/shiftsThunk";
import { signIn } from "../../../../store/thunks/waitersThunk";
import Numpad from "../../elements/numpad";

const WaiterLoginForm = () => {
    
    const history = useHistory();
	const dispatch = useDispatch();
	const [pin, setPin] = React.useState("");

    const handleInputChange = (element) => {
		const { value } = element.target
		setPin(value);
	}
    
	const handleFormSubmit = async (event) => {
		event.preventDefault();
		await dispatch(signIn(pin))
			.then(unwrapResult)
			.then(async () => {
                await dispatch(fetchCurrentShift())
                    .then(unwrapResult)
                    .then(result => {
                        if (result.shift.is_closed === 0) {
                            return history.replace("/dashboard");
                        }

                        return history.replace("/shift/start-day");
                    });
			}).catch(exception => {
				console.log(exception);
			});
	}

    return ( 
        <React.Fragment>
            <div className="pt-100 px-24">
                <div className="text-center">
                    <img src={require("../../../assets/images/logo.png").default} alt="logo" />
                </div>
                <form className="mt-5" onSubmit={handleFormSubmit}>
                    <div className="form-group">
                        <div className="input-icon input-icon-left">
                            <input id="pin" name="pin" type="password" className="form-control form-control-lg" value={pin} onChange={handleInputChange} placeholder="Enter your pin here" />
                            <span>
                                <i className="la la-unlock-alt" />
                            </span>
                        </div>
                    </div>
                    <div className="form-group">
                        <Numpad 
                            handleClick={number => {
                                if (number === "clr") {
                                    setPin("");
                                } else {
                                    setPin(pin.concat(number));
                                }
                            }} 
                        />
                    </div>
                    <div className="form-group mt-5">
                        <button className="btn btn-primary mr-2 uppercase w-full" style={{ display: "inline-block" }}>Sign In</button>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
}
 
export default WaiterLoginForm;