import React from "react";
import { orderBy } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from '@reduxjs/toolkit'
import { fetchSalesInvoices, fetchBill } from "../../../store/thunks/salesInvoicesThunk";
import { getSalesOrderEntities, unloadSalesOrders } from "../../../store/slices/datasources/salesOrdersSliceDatasource";
import { getDiningTableEntities, unloadDiningTables } from "../../../store/slices/datasources/diningTablesSliceDatasource";
import { getSalesInvoices, getSalesInvoicePagination, unloadSalesInvoices } from "../../../store/slices/datasources/salesInvoicesSliceDatasource";
import { printBill } from "../../helpers/printing";
import WithLoadingPage from "../commons/withLoadingPage";
import PageSubHeader from "../elements/pageSubHeader";
import Paginator from "../elements/paginator";
import DataTable from "./components/dataTable";

const SalesInvoices = props => {
    
    const dispatch = useDispatch();
    const salesOrderEntities = useSelector(getSalesOrderEntities);
    const diningTableEntities = useSelector(getDiningTableEntities);
    const salesInvoices = useSelector(getSalesInvoices);
    const pagination = useSelector(getSalesInvoicePagination);
    const { setLoading } = props;
    const [search, setSearch] = React.useState("");
    const [currentPage, setCurrentPage] = React.useState(1);

    React.useEffect(() => {
        return () => {
            dispatch(unloadSalesOrders());
            dispatch(unloadDiningTables());
            dispatch(unloadSalesInvoices());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        async function initialize() {
            setLoading(true);
            await dispatch(fetchSalesInvoices(getQueryFilter()));
            setLoading(false);
        }
        
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, currentPage]);

    const getQueryFilter = () => {
        const queryFilter = {
            withSalesOrder: true,
            withDiningTable: true,
            withCashier: true,
            withSalesInvoiceDetails: true,
            withSalesInvoicePayments: true,
            search: search,
            page: currentPage,
            paginate: 25,
        };

        return {
            query: queryFilter
        };
    }

    const handleSearchInputChange = element => {
		const { value } = element.target;
		setSearch(value);
	}

    const handleSearch = async () => {
        setLoading(true);
        await dispatch(fetchSalesInvoices(getQueryFilter()));
        setLoading(false);
    }

    const handlePageChange = page => {
        if (currentPage !== page) {
			setCurrentPage(page);
		}
    }

    const handlePrintBill = async salesInvoiceId => {
        setLoading(true);
        await dispatch(fetchBill({query: {id: salesInvoiceId}}))
            .then(unwrapResult)
            .then(response => {
                setLoading(false);
                printBill(response.bill);
            })
            .catch(exception => {
                setLoading(false);
            });
    }

    return (
        <React.Fragment>
            <PageSubHeader title="Sales Invoices" />
            <div className="w-full">
                <div className="classic card">
                    <div className="card-header bg-light-600">
                        <div className="flex align-middle">
                            <div className="input-group">
                                <input id="search" name="search" type="text" className="form-control input-group-item" placeholder="Search..." value={search} onChange={handleSearchInputChange} />
                                <button className="btn btn-primary uppercase input-group-item" onClick={handleSearch}>
                                    <i className="las la-search" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="card-body text-left">
                        <DataTable 
                            salesInvoices={orderBy(salesInvoices, "id", "desc")} 
                            salesOrderEntities={salesOrderEntities} 
                            diningTableEntities={diningTableEntities} 
                            handlePrintBill={handlePrintBill} 
                        />
                        <Paginator pagination={pagination} handlePageChange={handlePageChange} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
 
export default WithLoadingPage(SalesInvoices);