import React from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { unwrapResult } from '@reduxjs/toolkit'
import { fetchShift, fetchCashierShiftReport } from "../../../store/thunks/shiftsThunk";
import { getShift, unloadShifts } from "../../../store/slices/datasources/shiftsSliceDatasource";
import { getShiftDetailEntities, unloadShiftDetails } from "../../../store/slices/datasources/shiftDetailsSliceDatasource";
import { getCurrentStoreBranch } from "../../helpers/authentication";
import { printChangeOfShift, printEndOfShift } from "../../helpers/printing";
import WithLoadingPage from "../commons/withLoadingPage";
import PageSubHeader from "../elements/pageSubHeader";

const ShiftDetail = props => {
    
    const dispatch = useDispatch();
    const routeParams = useParams();
    const { id: shiftId } = routeParams;
    const { id: storeBranchId, name: storeBranchName } = getCurrentStoreBranch();
	const shift = useSelector(getShift);
	const shiftDetails = useSelector(getShiftDetailEntities);
    const { setLoading } = props;
    const [request, setRequest] = React.useState({ 
        is_closed: 0,
        from_date: "", 
        start_by: "", 
        opening_cash_balance: 0, 
        total_cash_received: 0, 
        current_balace: 0,
        shift_details: [],
        summary: {
            serving_order: {
                total_serving_order: 0,
                grand_total: 0,
            },
            void_order: {
                total_void_order: 0,
                grand_total: 0
            },
            cancelled_menus: [],
            sales_invoice_summary: {
                net_sales: 0,
                total_bill_discount: 0,
                total_service_charge: 0,
                total_vat: 0,
                gross_sales: 0,
                total_pax: 0,
                avg_net_per_pax: 0,
                avg_gross_per_pax: 0,
                number_of_bills: 0,
                avg_net_per_bill: 0,
                avg_gross_per_bill: 0,
            },
            sales_invoice_menus: [],
            sales_invoice_payments: [],
            total_sales_invoice_by_payment_method: {
                cash: 0,
                non_cash: 0,
                other: 0
            }
        }
     });

    React.useEffect(() => {
        return () => {
            dispatch(unloadShiftDetails());
            dispatch(unloadShifts());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {	
        async function initialize() {
            setLoading(true);
            await dispatch(fetchShift(getQueryFilter()));
            setLoading(false);
        }
		
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, shiftId]); 

    React.useEffect(() => {
		if (shift && shift.summary) {
			const mapViewToModel = {
				is_closed: shift.is_closed,
				from_date: moment(shift.from_date).format("DD-MM-YYYY HH:mm"),
				start_by: shift.start_by,
				opening_cash_balance: shift.opening_cash_balance,
                total_cash_received: shift.summary.total_sales_invoice_by_payment_method.cash,
                current_balace: Number(shift.opening_cash_balance) + Number(shift.summary.total_sales_invoice_by_payment_method.cash),
                shift_details: shift.shift_details,
                summary: {
                    serving_order: {
                        total_serving_order: shift.summary.serving_order.total_serving_order,
                        grand_total: shift.summary.serving_order.grand_total,
                    },
                    void_order: {
                        total_void_order: shift.summary.void_order.total_void_order,
                        grand_total: shift.summary.void_order.grand_total
                    },
                    cancelled_menus: shift.summary.cancelled_menus,
                    sales_invoice_summary: {
                        net_sales: shift.summary.sales_invoice_summary.net_sales,
                        total_bill_discount: shift.summary.sales_invoice_summary.total_bill_discount,
                        total_service_charge: shift.summary.sales_invoice_summary.total_service_charge,
                        total_vat: shift.summary.sales_invoice_summary.total_vat,
                        gross_sales: shift.summary.sales_invoice_summary.gross_sales,
                        number_of_bills: shift.summary.sales_invoice_summary.number_of_bills,
                        avg_net_per_bill: shift.summary.sales_invoice_summary.avg_net_per_bill,
                        avg_gross_per_bill: shift.summary.sales_invoice_summary.avg_gross_per_bill,
                        total_pax: shift.summary.sales_invoice_summary.total_pax,
                        avg_net_per_pax: shift.summary.sales_invoice_summary.avg_net_per_pax,
                        avg_gross_per_pax: shift.summary.sales_invoice_summary.avg_gross_per_pax,
                    },
                    sales_invoice_menus: shift.summary.sales_invoice_menus,
                    sales_invoice_payments: shift.summary.sales_invoice_payments,
                    total_sales_invoice_by_payment_method: {
                        cash: shift.summary.total_sales_invoice_by_payment_method.cash,
                        non_cash: shift.summary.total_sales_invoice_by_payment_method.non_cash,
                        other: shift.summary.total_sales_invoice_by_payment_method.other
                    }
                }
			}

			setRequest(prev => { 
                return { 
                    ...prev, 
                    ...mapViewToModel 
                }
            });
		}
	}, [shift]);

    const getQueryFilter = () => {
        const queryFilter = { 
            id: shiftId, 
            withShiftDetails: true,
            withSummary: true,
        };

        return {
            query: queryFilter
        };
    }

    const handlePrintEndOfShiftReport = async () => {
        setLoading(true);

        const query = {
            store_branch_id: storeBranchId,
            id: shiftId,
        };

        await dispatch(fetchCashierShiftReport({ query }))
			.then(unwrapResult)
			.then(response => {
				setLoading(false);
                printEndOfShift(response.shift_report);
			})
			.catch(exception => {
				setLoading(false);
			});
    }

    const handlePrintCashierShiftReport = async shiftDetailId => {
        setLoading(true);

        const query = {
            store_branch_id: storeBranchId,
            id: shiftId,
            shift_detail_id: shiftDetailId,
        };

        await dispatch(fetchCashierShiftReport({ query }))
			.then(unwrapResult)
			.then(response => {
				setLoading(false);
                printChangeOfShift(response.shift_report);
			})
			.catch(exception => {
				setLoading(false);
			});
    }

    return (
        <React.Fragment>
            <PageSubHeader title="Shift Log" />
            <div className="w-full">
                <div className="classic card">
                    <div className="card-body text-left">
                        <div className="grid grid-rows-2 grid-flow-col gap-8">
                            <div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="outlet">Outlet</label>
                                    <input name="outlet" type="text" className="form-control" value={storeBranchName} readOnly={true} />
                                </div>
                            </div>
                            <div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="opening_cash_balance">Opening Balance</label>
                                    <input id="opening_cash_balance" name="opening_cash_balance" type="text" className="form-control text-right" value={Number(request.opening_cash_balance).toLocaleString()} readOnly={true} />
                                </div>
                            </div>
                            <div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="start_by">Start By</label>
                                    <input name="start_by" type="text" className="form-control" value={request.start_by} readOnly={true} />
                                </div>
                            </div>
                            <div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="total_cash_received">Total Cash Received</label>
                                    <input name="total_cash_received" type="text" className="form-control text-right" value={Number(request.total_cash_received).toLocaleString()} readOnly={true} />
                                </div>
                            </div>
                            <div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="open_at">Start at</label>
                                    <input name="open_at" type="text" className="form-control" value={request.from_date} readOnly={true} />
                                </div>
                            </div>
                            <div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="current_balace">Expected Cash Balance</label>
                                    <input name="current_balace" type="text" className="form-control text-right" value={Number(request.current_balace).toLocaleString()} readOnly={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer bg-light-600 text-right">
                        {
                            request.is_closed === 1 && 
                            <button className="btn btn-primary w-auto mr-10" style={{ display: "inline-block" }} onClick={handlePrintEndOfShiftReport}>
                                <i className="fas fa-print" />
                                End of Shift
                            </button>
                        }
                        &nbsp;
                    </div>
                </div>
            </div>
            <div className="flex">
                <div className="w-1/2 mr-10">
                    <div className="classic card">
                        <div className="card-header bg-light-600">
                            <h3 className="card-title">Shift Summary</h3>
                        </div>
                        <div className="card-body text-left">
                            <table className="table table-hover text-14">
                                <tbody>
                                    <tr>
                                        <td>Serving Orders</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;&nbsp;&nbsp;&nbsp;Total Serving Order</td>
                                        <td className="text-right" style={{paddingRight:"20px"}}>{Number(request.summary.serving_order.total_serving_order).toLocaleString()}</td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;&nbsp;&nbsp;&nbsp;Grand Total</td>
                                        <td className="text-right" style={{paddingRight:"20px"}}>{Number(request.summary.serving_order.grand_total).toLocaleString()}</td>
                                    </tr>

                                    <tr>
                                        <td>Sales Summary</td>
                                        <td></td>
                                    </tr>
                                   
                                    <tr>
                                        <td>&nbsp;&nbsp;&nbsp;&nbsp;Net Sales</td>
                                        <td className="text-right" style={{paddingRight: "20px"}}>{Number(request.summary.sales_invoice_summary.net_sales).toLocaleString()}</td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;&nbsp;&nbsp;&nbsp;Total Bill Discount</td>
                                        <td className="text-right" style={{paddingRight: "20px"}}>{Number(request.summary.sales_invoice_summary.total_bill_discount).toLocaleString()}</td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;&nbsp;&nbsp;&nbsp;Total Service Charge</td>
                                        <td className="text-right" style={{paddingRight: "20px"}}>{Number(request.summary.sales_invoice_summary.total_service_charge).toLocaleString()}</td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;&nbsp;&nbsp;&nbsp;Total VAT</td>
                                        <td className="text-right" style={{paddingRight: "20px"}}>{Number(request.summary.sales_invoice_summary.total_vat).toLocaleString()}</td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;&nbsp;&nbsp;&nbsp;Total Gross Sales</td>
                                        <td className="text-right" style={{paddingRight: "20px"}}>{Number(request.summary.sales_invoice_summary.gross_sales).toLocaleString()}</td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;&nbsp;&nbsp;&nbsp;Total Pax</td>
                                        <td className="text-right" style={{paddingRight: "20px"}}>{Number(request.summary.sales_invoice_summary.total_pax).toLocaleString()}</td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;&nbsp;&nbsp;&nbsp;Avg. Net Sales per Pax</td>
                                        <td className="text-right" style={{paddingRight: "20px"}}>{Number(request.summary.sales_invoice_summary.avg_net_per_pax).toLocaleString()}</td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;&nbsp;&nbsp;&nbsp;Avg. Gross Sales per Pax</td>
                                        <td className="text-right" style={{paddingRight: "20px"}}>{Number(request.summary.sales_invoice_summary.avg_gross_per_pax).toLocaleString()}</td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;&nbsp;&nbsp;&nbsp;Number of Bills</td>
                                        <td className="text-right" style={{paddingRight: "20px"}}>{Number(request.summary.sales_invoice_summary.number_of_bills).toLocaleString()}</td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;&nbsp;&nbsp;&nbsp;Avg. Net Sales per Bill</td>
                                        <td className="text-right" style={{paddingRight: "20px"}}>{Number(request.summary.sales_invoice_summary.avg_net_per_bill).toLocaleString()}</td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;&nbsp;&nbsp;&nbsp;Avg. Gross Sales per Bill</td>
                                        <td className="text-right" style={{paddingRight: "20px"}}>{Number(request.summary.sales_invoice_summary.avg_gross_per_bill).toLocaleString()}</td>
                                    </tr>

                                    <tr>
                                        <td>Void Transactions</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;&nbsp;&nbsp;&nbsp;Total Void Orders</td>
                                        <td className="text-right" style={{paddingRight: "20px"}}>{Number(request.summary.void_order.total_void_order).toLocaleString()}</td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;&nbsp;&nbsp;&nbsp;Grand Total</td>
                                        <td className="text-right" style={{paddingRight: "20px"}}>{Number(request.summary.void_order.grand_total).toLocaleString()}</td>
                                    </tr>
                                </tbody>
                            </table>
                            &nbsp;
                        </div>
                    </div>
                </div>
                <div className="w-1/2">
                    <div className="classic card" style={{ height: "33.33%"}}>
                        <div className="card-header bg-light-600">
                            <h3 className="card-title">Shift Logs</h3>
                        </div>
                        <div className="card-body text-left">
                            <table className="table text-14">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Start By</th>
                                        <th>Shift In</th>
                                        <th>Close By</th>
                                        <th>Shift Out</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {request.shift_details.map((shiftDetailId, index) => 
                                        <tr key={shiftDetailId}>
                                            <td width="5%">{index + 1}</td>
                                            <td>{shiftDetails[shiftDetailId].start_by}</td>
                                            <td>{moment(shiftDetails[shiftDetailId].shift_in).format("DD-MM-YYYY HH:mm")}</td>
                                            <td>{shiftDetails[shiftDetailId].closed_by}</td>
                                            <td>{shiftDetails[shiftDetailId].shift_out ? moment(shiftDetails[shiftDetailId].shift_out).format("DD-MM-YYYY HH:mm") : ""}</td>
                                            <td width="15%">
                                                {
                                                    request.is_closed === 1 && 
                                                    <button className="btn btn-primary w-auto text-base" style={{ display: "inline-block" }} onClick={() => handlePrintCashierShiftReport(shiftDetailId)}>
                                                        <i className="fas fa-print" />
                                                        Shift Report
                                                    </button>
                                                }
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="classic card" style={{ height: "33.33%"}}>
                        <div className="card-header bg-light-600">
                            <h3 className="card-title">Detail Payments</h3>
                        </div>
                        <div className="card-body text-left">
                            <table className="table table-hover text-14">
                                <tbody>
                                    {request.summary.sales_invoice_payments.map(salesInvoicePayment => 
                                        <tr key={salesInvoicePayment.id}>
                                            <td>{salesInvoicePayment.name}</td>
                                            <td className="text-right" style={{paddingRight: "20px"}} width="10%">{Number(salesInvoicePayment.number_of_invoice).toLocaleString()}</td>
                                            <td className="text-right" style={{paddingRight: "20px"}} width="15%">{Number(salesInvoicePayment.total_amount).toLocaleString()}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="classic card" style={{ height: "calc(30% - 4px)"}}>
                        <div className="card-header bg-light-600">
                            <h3 className="card-title">Total By Payment Method</h3>
                        </div>
                        <div className="card-body text-left">
                            <table className="table table-hover text-14">
                                <tbody>
                                    <tr>
                                        <td>Cash</td>
                                        <td className="text-right" style={{paddingRight: "20px"}} width="15%">{Number(request.summary.total_sales_invoice_by_payment_method.cash).toLocaleString()}</td>
                                    </tr>
                                    <tr>
                                        <td>Non Cash</td>
                                        <td className="text-right" style={{paddingRight: "20px"}} width="15%">{Number(request.summary.total_sales_invoice_by_payment_method.non_cash).toLocaleString()}</td>
                                    </tr>
                                    <tr>
                                        <td>Others</td>
                                        <td className="text-right" style={{paddingRight: "20px"}} width="15%">{Number(request.summary.total_sales_invoice_by_payment_method.other).toLocaleString()}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full">
                <div className="classic card h-full">
                    <div className="card-header bg-light-600">
                        <h3 className="card-title">Sales Menus</h3>
                    </div>
                    <div className="card-body text-left">
                        <table className="table table-hover text-14">
                            <thead className="thead-light">
                                <tr>
                                    <th>Menu</th>
                                    <th className="text-right" width="20%">Quantity</th>
                                    <th className="text-right" width="20%">Total</th>
                                    <th className="text-right" width="20%">Total Discount</th>
                                    <th className="text-right" width="20%">Sub Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {request.summary.sales_invoice_menus.map(salesInvoiceMenu => 
                                    <tr key={salesInvoiceMenu.menu_id}>
                                        <td>{salesInvoiceMenu.menu.name}</td>
                                        <td className="text-right" style={{paddingRight: "20px"}}>{Number(salesInvoiceMenu.quantity).toLocaleString()}</td>
                                        <td className="text-right" style={{paddingRight: "20px"}}>{Number(salesInvoiceMenu.total).toLocaleString()}</td>
                                        <td className="text-right" style={{paddingRight: "20px"}}>{Number(salesInvoiceMenu.total_discount).toLocaleString()}</td>
                                        <td className="text-right" style={{paddingRight: "20px"}}>{Number(salesInvoiceMenu.sub_total).toLocaleString()}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
 
export default WithLoadingPage(ShiftDetail);
