import React from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from '@reduxjs/toolkit'
import { Dialog, Tab, Transition } from "@headlessui/react";
import { fetchSalesOrderInfo } from "../../../../store/thunks/salesOrdersThunk";

const FormSplitBill = ({ showDialog, salesOrderCode, handleCloseDialog }) => {
    
    const dispatch = useDispatch();
    const [items, setItems] = React.useState([]);
    
    React.useEffect(() => {
        async function initialize() {
            await dispatch(fetchSalesOrderInfo(getQueryFilter()))
                .then(unwrapResult)
                .then(respose => {
                    setItems(respose.salesOrder.sales_order_details);
                });
        }
		
        initialize();
        console.log(items);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salesOrderCode]);

    const getQueryFilter = () => {
        const queryFilter = {
            code: salesOrderCode,
            withSalesOrderDetails: true
        };

        return {
            query: queryFilter
        };
    }

    return (
        <Transition.Root show={showDialog} as={React.Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={handleCloseDialog}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child as={React.Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <Dialog.Overlay className="fixed inset-0 bg-light-600 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &nbsp;
                    </span>
                    <Transition.Child as={React.Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <div className="inline-block overflow-hidden shadow-xl text-left transform transition-all align-middle w-1/2">
                            <div className="widget card">
                                <div className="card-header bg-primary">
                                    <h3 className="card-title text-white">Split Bill</h3>
                                </div>
                                <div className="card-body">
                                    <div className="flex">
                                        <div className="pr-15 w-1/2 mr-20 h-450 overflow-y-scroll">
                                            {items.map(item => 
                                                <div key={item.id} className="widget card shadow-md mb-7">
                                                    <div className="card-body">
                                                        <div className="flex">
                                                            <div className="flex-none w-32 text-14 my-auto">
                                                                {Number(item.quantity).toLocaleString()}
                                                            </div>
                                                            <div className="flex-grow text-14">
                                                                {item.menu.name}
                                                                <br />
                                                                @ <strong>{Number(item.price).toLocaleString()}</strong> | <strong>Total: {Number(item.sub_total).toLocaleString()}</strong>
                                                            </div>
                                                            <div className="flex-none w-32 text-14 my-auto">
                                                                <button className="btn btn-primary">
                                                                    <i className="fas fa-plus m-auto" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="w-1/2 ml-2">
                                            <Tab.Group as={React.Fragment}>
                                                <div className="border-b border-gray-200">
                                                    <Tab.List className="flex space-x-4">
                                                        <Tab className={({ selected }) => selected ? "text-primary-500 px-12 py-8 font-medium text-14 rounded-md" : "text-gray-500 hover:text-gray-800 px-12 py-8 font-medium text-14 rounded-md"}>Tab 1</Tab>
                                                    </Tab.List>
                                                </div>
                                                <Tab.Panels>
                                                    <Tab.Panel className="p-16">Content 1</Tab.Panel>
                                                </Tab.Panels>
                                            </Tab.Group>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer bg-gray-100 text-right">
                                    <button type="button" className="btn btn-outline-danger uppercase" onClick={handleCloseDialog}>
                                        &nbsp;&nbsp;Cancel&nbsp;&nbsp;
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
 
export default FormSplitBill;
